<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button-group class="vg_mr_8" style="position: relative; top: -1px">
          <el-button type="primary" size="small" @click="changeDataType(2)">全部</el-button>
          <el-button type="primary" size="small" @click="changeDataType(0)">业务部门</el-button>
          <el-button type="primary" size="small" @click="changeDataType(1)">设计部门</el-button>
        </el-button-group>
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="统计开始日期"
          end-placeholder="统计结束日期"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList()" class="vg_mt_8">查询 </el-button>
        <el-button
          v-if="activeName === 'first'"
          type="info"
          size="small"
          class="vg_mt_8"
          @click="buttonRefresh1()"
          icon="el-icon-refresh-right"
        >
          刷新</el-button
        >
        <el-button v-else type="info" size="small" class="vg_mt_8" @click="buttonRefresh2()" icon="el-icon-refresh-right"> 刷新</el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
      </div>
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="设计稿明细" name="second" :key="'second'">
          <DynamicUTable
            ref="multiTable2"
            :tableData="designDraftDetailsTableData"
            :columns="designDraftDetails"
            v-loading="loadFlag2"
            :total-page="totalPage"
            :need-search="true"
            :need-check-box="false"
            :need-pagination="true"
            :need-fixed-height="true"
            :rowStyle="() => ({ height: '80px' })"
            @getTableData="getDesignDraftDetails"
          >
          </DynamicUTable>
        </el-tab-pane>
        <el-tab-pane label="统计明细" name="first" :key="'first'">
          <DynamicUTable
            id="multiTable"
            ref="multiTable"
            :tableData="statisticalDetailsTableData"
            :columns="statisticalDetails"
            v-loading="loadFlag1"
            :need-check-box="false"
            :need-pagination="false"
            :need-fixed-height="true"
            :need-all-column-summary="true"
            :need-all-column-summary-to-fixed="0"
            :showSummary="true"
            @getTableData="getStatisticalDetails"
          >
          </DynamicUTable>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { desiAPI } from '@api/modules/desi';
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { designDraftDetailsProperties, statisticalDetailsProperties } from '@/views/DevelopManagement/DesiManage/desi';
import { getDataCenterByPermId } from '@api/public';
import htmlToExcel from '@/plugins/htmlToExcel';

export default {
  name: 'DataList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      activeName: 'second',
      dateRange1: [],
      statisticalDetails: cloneDeep(statisticalDetailsProperties),
      statisticalDetailsTableData: [],
      designDraftDetails: cloneDeep(designDraftDetailsProperties),
      designDraftDetailsTableData: [],
      loadFlag1: true,
      loadFlag2: true,
      totalPage: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.changeProperties();
      await this.getDataList();
    },
    async changeProperties() {
      setTimeout(() => {
        this.$refs.multiTable2.tableMaxHeight = this.$refs.multiTable.tableMaxHeight - 52;
      });
      let list = await getDataCenterByPermId({ id: 10002 });
      list
        .sort((a, b) => a.id - b.id)
        .forEach(item => {
          let obj = cloneDeep({ label: '', prop: '', itemType: 'input', input: false, widthAuto: true, sortable: false });
          obj.label = item.param1 + '类';
          obj.prop = item.param1;
          this.statisticalDetails.push(obj);
        });
    },
    async getDataList() {
      this.loadFlag1 = true;
      this.loadFlag2 = true;
      this.getStatisticalDetails();
      this.getDesignDraftDetails();
      setTimeout(() => {
        this.loadFlag1 = false;
        this.loadFlag2 = false;
      });
    },
    getStatisticalDetails() {
      this.loadFlag1 = true;
      let searchForm1 = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange1 || [];
      let start = startTime ? startTime : null;
      let end = endTime ? endTime : null;
      searchForm1.start_time = start;
      searchForm1.end_time = end;
      desiAPI.getDesignCountVoList(searchForm1).then(({ data }) => {
        this.statisticalDetailsTableData = data;
        this.loadFlag1 = false;
      });
    },
    getDesignDraftDetails() {
      let searchForm2 = this.$refs.multiTable2.searchForm;
      let [startTime, endTime] = this.dateRange1 || [];
      searchForm2.start_time = startTime ? startTime : null;
      searchForm2.end_time = endTime ? endTime : null;
      desiAPI.getDesignCountDetailVoList(searchForm2).then(({ data }) => {
        this.totalPage = data.total;
        this.designDraftDetailsTableData = data.list;
        this.loadFlag1 = false;
      });
    },
    buttonRefresh1() {
      this.dateRange1 = [];
      this.$refs.multiTable.resetFields();
      this.getStatisticalDetails();
    },
    buttonRefresh2() {
      this.dateRange1 = [];
      this.$refs.multiTable2.resetFields();
      this.getStatisticalDetails();
    },
    exportExcel() {
      htmlToExcel.getExcel(`#multiTable`, '统计明细');
    },
    async changeDataType(type) {
      if (type === 2) type = '';
      await (this.$refs.multiTable.searchForm.dept_type = type);
      await this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss"></style>
